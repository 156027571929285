import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import { Feature, Join } from "./reseller-partner"
import { SolutionTopBanner } from "../../components/block"
// import "../../styles/channel-partner.less"
import "../../styles/agent-partner.less"
const loadText = require("src/utils").loadText
const static_data = loadText("master-agent-refferral")
import { PartnerPageForm, PartnerPopButton } from "../../components/form-biz"
export default function Home({ data }) {
  const { strapiIndex } = data

  return (
    <div className="agent">
      <SEO {...static_data.seo} />
      <SolutionTopBanner
        {...static_data.topBanner}
        height={636}
        pic={{
          src: "/partners/agent/topBanner-pic.png",
          style: {
            position: "absolute",
            bottom: "-40px",
            right: "40px",
          },
        }}
        bannerPic={{
          src: `/partners/agent/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        button={PartnerPopButton}
        bgColor="linear-gradient(157deg,rgba(239,250,255,1) 0%,rgba(210,240,255,1) 100%)"
        bgPic="/partners/agent/topBanner-bg.svg"
        source="agentPartner"
      />
      <Feature
        title={static_data.feature.title}
        reason={static_data.feature.reason.map((r, idx) => ({
          ...r,
          src: `/partners/agent/reason-${idx + 1}.png`,
        }))}
        bgPic="/partners/agent/feature-bg.svg"
      />
      <Join data={strapiIndex.channelPartnerLogos} />
      <div style={{ background: "rgb(225,249,255)" }}>
        <PartnerPageForm {...static_data.form} source="agentPartner" />
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    strapiIndex {
      channelPartnerLogos {
        id
        localFile {
          publicURL
        }
        alternativeText
      }
    }
  }
`
