import React from "react"
import Footer from "../../components/footer"
// import { graphql } from "gatsby"
import "../../styles/channel-partner.less"
import SEO from "../../components/SEO"
import { SolutionTopBanner } from "../../components/block"
// import Drop from "../../components/drop"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
const settings = {
  className: "center",
  infinite: true,
  // slidesToShow: 4,
  speed: 500,
  autoplaySpeed: 5000,
  rows: 4,
  slidesToShow: 2,
  arrows: false,
  autoplay: true,
  // responsive: [
  //   {
  //     breakpoint: 640,
  //     settings: {},
  //   },
  // ],
}
const loadText = require("src/utils").loadText
const text = loadText("channel-partner")
import { PartnerPageForm, PartnerPopButton } from "../../components/form-biz"
export const Feature = ({ title, reason, bgPic }) => {
  return (
    <div
      className="channel-feature"
      style={{ backgroundImage: `url("${bgPic}")` }}
    >
      <div className="font-36 semi-bold t-color text-center mobile-padding">
        {title}
      </div>
      <div className="page-middle">
        <div className="channel-feature-content">
          {reason.map(({ title, content, alt, src }, idx) => {
            return (
              <div className="channel-feature-contentItem  mt-56 clearfix mobile-padding block-middle">
                <div className="imgContainer">
                  <picture>
                    <source
                      srcSet={`${src.replace(".png", "")}-m.png 640w `}
                      media="(max-width: 640px)"
                    />
                    <img src={src} alt={alt || title} />
                    <div className="only-mobile font-28 semi-bold">{title}</div>
                  </picture>
                </div>
                <div className="channel-feature-contentItem-container textContainer">
                  <div className="font-28 t-color semi-bold channel-feature-contentItem-title only-desktop">
                    {title}
                  </div>
                  <ul className="mt-24 content">
                    {content.map(c => (
                      <li className="font-20 content-item">
                        <span>{c}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const Join = ({ data }) => {
  return (
    <div className="channel-join">
      <div className="page-middle">
        <div className="font-36 semi-bold t-color">{text.join.title}</div>
        <div className="logo-container only-desktop">
          {data.map(({ alternativeText, localFile }) => (
            <img
              src={localFile.publicURL}
              alt={alternativeText}
              className="logo-item"
            />
          ))}
        </div>
        <div className="logo-container-m only-mobile">
          <Slider {...settings}>
            {data.map(({ alternativeText, localFile }) => (
              <img
                src={localFile.publicURL}
                alt={alternativeText}
                className="logo-item"
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default function Home() {
  // const { strapiIndex } = data
  return (
    <div className="channel">
      <SEO {...text.seo} />
      <SolutionTopBanner
        {...text.topBanner}
        pic={{
          src: "/partners/channel/topBanner-pic.png",
          style: {
            position: "absolute",
            bottom: "-56px",
            right: "40px",
          },
        }}
        bannerPic={{
          src: `/partners/channel/topBanner-bg-reseller-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        picM={{
          src: "/partners/channel/topBanner-pic-m.png",
          style: {
            position: "absolute",
            bottom: "-56px",
            right: "0px",
          },
        }}
        bgColor="linear-gradient(
      360deg, #F2FDFF 0%, #E0F9FF 100%)"
        bgPic="/partners/channel/topBanner-bg.svg"
        button={PartnerPopButton}
        source="resellerPartner"
      />
      <Feature
        title={text.feature.title}
        reason={text.feature.reason.map((r, idx) => ({
          ...r,
          src: `/partners/channel/reason-${idx + 1}.png`,
        }))}
        // bgPic="/partners/channel/feature-bg.svg"
      />

      {/* <Join data={strapiIndex.channelPartnerLogos} /> */}
      <PartnerPageForm {...text.form} source="resellerPartner" />
      <Footer />
    </div>
  )
}

// export const query = graphql`
//   {
//     strapiIndex {
//       channelPartnerLogos {
//         id
//         localFile {
//           publicURL
//         }
//         alternativeText
//       }
//     }
//   }
// `
